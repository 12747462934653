import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import mobile from "is-mobile";
import { useIdle } from "react-use";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageType } from "../components/context/page-type";
import { FilterContext } from "../components/context/filter-context";

// Components
import { Filters } from "../components/shop/filters";
import { InactivityPopUp } from "../components/popups/inactivity-popup";
import { HomepageArtwork } from "../components/homepage/homepage-artwork";

const Page = styled.div`
  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    // transition: 249ms background-color ease;
    background-color: #fffcfa;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  & .filters-container {
    // transition: 249ms background-color ease;
    background-color: #fffcfa;
  }

  & img {
    transition: 250ms opacity ease;
  }

  &.loading {
    & img {
      opacity: 0 !important;
    }
  }

  &.loaded {
    & img {
      opacity: 1;
    }
  }

  &.active-artwork {
    & .header,
    & .filters-container {
      background-color: #e9cfc0;
    }

    & .artwork-container {
      opacity: 0;

      & .artwork-details,
      &.active-artwork {
        opacity: 1;
      }
    }

    & button {
      &.active,
      &.filter.active {
        color: #658ba5 !important;
      }
    }

    & .spacer {
      & p {
        color: #658ba5;
      }
    }
  }

  & .artwork-container {
    transition: 250ms opacity ease;

    & img {
      z-index: 0;
    }

    & a {
      display: block;
      width: fit-content;
      position: relative;

      cursor: url(/icons/cursor-plus-on-white@1x.png) 12 12, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/cursor-plus-on-white@1x.png) 1x,
            url(/icons/cursor-plus-on-white@2x.png) 2x
          )
          12 12,
        pointer !important;
    }

    & .artist-title {
      letter-spacing: 0.08em;
    }

    & p {
      font-size: 14px;
      line-height: 19px;

      margin: 0;

      &.artwork-title {
        margin: 15px 0 0 0;
      }
    }
  }

  @media (max-width: 500px) {
    & .artwork-container {
      & p {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);

  &.gallery {
    grid-column-gap: 50px;

    & .artwork-details {
      transition: 250ms opacity ease;

      opacity: 0;
    }

    & .artwork-container {
      &:nth-of-type(6n + 1) {
        grid-column: 1 / 5;
        margin: 0 0 220px 0;
      }

      &:nth-of-type(6n + 2) {
        grid-column: 6 / 10;
        margin: 140px 0 220px 0;
      }

      &:nth-of-type(6n + 3) {
        grid-column: 3 / 7;
        margin: 0 0 220px 0;
      }

      &:nth-of-type(6n + 4) {
        grid-column: 1 / 4;
        margin: 0 0 220px 0;
      }

      &:nth-of-type(6n + 5) {
        grid-column: 5 / 10;
        margin: 400px 0 220px 0;
      }

      &:nth-of-type(6n + 6) {
        grid-column: 1 / 6;
        margin: 0 0 220px 0;
      }
    }
  }

  &.overview {
    grid-column-gap: 100px;
    grid-row-gap: 145px;

    & .artwork-container {
      height: 100%;

      grid-column: span 3;

      & a {
        display: grid;
        grid-template-rows: auto 4em;
        height: 100%;

        & > div {
          width: 100%;
        }
      }

      & .aspect-ratio-container {
        display: flex;
        align-items: center;

        & img {
          height: auto;
        }
      }

      & .artwork-details {
        margin: 25px 0 0 0;

        & p {
          margin: 0;
        }

        @media (max-width: 500px) {
          margin: 5px 0 0 0;
        }
      }
    }
  }

  & .sold-out {
    & .sold-out-span {
      display: block;
      margin: 10px 0 0 0;

      width: 9px;
      height: 9px;

      border-radius: 100%;
      background-color: #f1624e;

      @media (max-width: 800px) {
        margin: 3px 0 0 0;

        width: 7px;
        height: 7px;
      }
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(6, 1fr);

    &.gallery {
      & .artwork-container {
        &:nth-of-type(5n + 1) {
          grid-column: 1 / 6;
          margin: 0 0 60px 0;
        }

        &:nth-of-type(5n + 2) {
          grid-column: 3 / 7;
          margin: 0 0 60px 0;
        }

        &:nth-of-type(5n + 3) {
          grid-column: 1 / 6;
          margin: 0 0 60px 0;
        }

        &:nth-of-type(5n + 4) {
          grid-column: 3 / 7;
          margin: 0 0 60px 0;
        }

        &:nth-of-type(5n + 5) {
          grid-column: 1 / 6;
          margin: 0 0 60px 0;
        }
      }
    }

    &.overview {
      grid-column-gap: 15px;
      grid-row-gap: 50px;
    }
  }

  &.no-results {
    display: block;
    grid-template-columns: unset;

    & .no-results-text {
      font-size: 16px;
      line-height: 22px;

      @media (max-width: 1440px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media (max-width: 500px) {
        font-size: 9px;
        line-height: 12px;
      }
    }
  }
`;

const FiltersContainer = styled.div`
  position: sticky;
  top: 75px;

  @media (max-width: 800px) {
    top: 56px;
  }

  z-index: 100;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  margin: 0 0 20px 0;
  padding: 0 0 5px 0;

  & button {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;

    transition: 250ms color ease;

    &.active {
      color: #dbaf96;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #dbaf96;
      }
    }

    &.filter {
      margin: 0 35px 0 0;

      &.active {
        color: #dbaf96;
      }

      &.active.active-artwork {
        color: #dbaf96;
      }
    }

    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }

  & .spacer {
    color: #dbaf96;

    & p {
      margin: 0 3px;
    }
  }

  @media (max-width: 500px) {
    & button {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const Shop = ({ data, location }) => {
  const [pageType, setPageType] = useContext(PageType);
  const [isLoading, setIsLoading] = useState(true);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 801px)");

  // Idle
  const isIdle = useIdle(15000); //15000
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    if (isIdle && isDesktop) {
      setIsPopUpOpen(true);
    }
  }, [isIdle, isDesktop]);

  // Context
  const [filterSettings, setFilterSettings] = useContext(FilterContext);

  // Is Mobile
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(mobile());
  }, []);

  // Active view
  const [view, setView] = useState(`gallery`);
  const [isArtworkActive, setIsArtworkActive] = useState(false);
  const [activeArtwork, setActiveArtwork] = useState(null);

  // Image Sizing
  const height = use100vh();

  // Filters
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  useEffect(() => {
    setPageType({
      page: `shop`,
      background: `#FFFCFA`,
      banner: true,
      bannerBackground: `#BDBFB7`,
      fillColor: `#DBAF96`,
      hoverColor: `#f9e0d2`,
      underlineColor: `#f9e0d2`,
    });
  }, []);

  useEffect(() => {
    if (isFiltersOpen) {
      setPageType({
        page: `shop`,
        background: `#FFFCFA`,
        banner: true,
        bannerBackground: `#BDBFB7`,
        fillColor: `#91A8B7`,
        hoverColor: `#f9e0d2`,
        underlineColor: `#f9e0d2`,
      });
    } else {
      setPageType({
        page: `shop`,
        background: `#FFFCFA`,
        banner: true,
        bannerBackground: `#BDBFB7`,
        fillColor: `#DBAF96`,
        hoverColor: `#f9e0d2`,
        underlineColor: `#f9e0d2`,
      });
    }
  }, [isFiltersOpen]);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        // updateArtistFilter;
        // updatePriceFilter;
        // updateSoldItemsFilter;

        if (location.state.resetFilters !== undefined) {
          setFilterSettings({
            activeArtistFilter: [],
            activePriceFilter: null,
            hideSoldItems: false,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (
          location.state.activeArtistFilter !== undefined &&
          location.state.activeArtistFilter !== null
        ) {
          setFilterSettings({
            ...filterSettings,
            activeArtistFilter: [location.state.activeArtistFilter],
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (isArtworkActive) {
      document
        .getElementById(`site-container`)
        .classList.add(`artwork-is-active`);
    } else {
      document
        .getElementById(`site-container`)
        .classList.remove(`artwork-is-active`);
    }
  }, [isArtworkActive]);

  const artists = Array.from(
    new Set(
      data.allShopifyProduct.nodes
        .map((artwork) => {
          const metafields =
            artwork.metafields.length >= 1 ? artwork.metafields : null;

          const artistMetafield =
            metafields !== null
              ? metafields.filter((field) => field.key === "artist")
              : null;

          if (artistMetafield !== null && artistMetafield.length >= 1) {
            return artistMetafield[0].value;
          } else {
            return null;
          }
        })
        .filter((artist) => artist !== null)
        .sort()
    )
  );

  // https://stackoverflow.com/questions/24173245/javascript-array-sort-by-last-name-first-name
  const sortedArtists = artists
    .sort((a, b) => b.split(" ")[1].localeCompare(a.split(" ")[1]))
    .reverse()
    .filter((artist) => !artist.includes("&"));

  const filteredArtists = sortedArtists.map((artist, index) => (
    <li
      key={`single_artist_item_${index}`}
      className={
        filterSettings.activeArtistFilter.includes(artist) ? `active` : ``
      }
    >
      <button
        className=""
        onClick={() => {
          if (filterSettings.activeArtistFilter.includes(artist)) {
            setFilterSettings({
              ...filterSettings,
              activeArtistFilter: filterSettings.activeArtistFilter.filter(
                (item) => item !== artist
              ),
            });
          } else {
            setFilterSettings({
              ...filterSettings,
              activeArtistFilter: [
                ...filterSettings.activeArtistFilter,
                artist,
              ],
            });
          }
        }}
      >
        {artist}
      </button>
    </li>
  ));

  const getArtistName = (artwork) => {
    const metafields =
      artwork.metafields.length >= 1 ? artwork.metafields : null;

    const artistMetafield =
      metafields !== null
        ? metafields
            .filter((field) => field.key === "artist")
            .map((field) => field.value)
        : null;

    if (artistMetafield !== null && artistMetafield.length >= 1) {
      return artistMetafield[0];
    } else {
      return ``;
    }
  };

  const artworks = data.allShopifyProduct.nodes
    .filter((artwork) => {
      if (filterSettings.activeArtistFilter.length === 0) {
        return artwork;
      } else {
        const match = filterSettings.activeArtistFilter.find((artist) => {
          if (getArtistName(artwork).includes(artist)) {
            return true;
          }
        });

        if (match !== undefined) {
          return artwork;
        }
      }
    })
    .filter((artwork) => {
      if (filterSettings.activePriceFilter === null) {
        return artwork;
      } else {
        if (
          artwork.priceRangeV2.minVariantPrice.amount <=
          filterSettings.activePriceFilter
        ) {
          return artwork;
        }
      }
    })
    .filter((artwork) => {
      if (filterSettings.hideSoldItems === false) {
        return artwork;
      } else {
        if (artwork.totalInventory >= 1) {
          return artwork;
        }
      }
    })
    .map((artwork, index) => {
      return (
        <HomepageArtwork
          key={`single_artwork_${index}`}
          artwork={artwork}
          index={index}
          view={view}
          getArtistName={getArtistName}
          activeArtwork={activeArtwork}
          setIsArtworkActive={setIsArtworkActive}
          setActiveArtwork={setActiveArtwork}
          isMobile={isMobile}
          height={height}
        />
      );
    });

  return (
    <>
      <PageSeo seoTitle="Shop | RAW Editions" seoImage={null} seoText={null} />

      <Page
        className={`${isArtworkActive ? `active-artwork` : ``} ${
          isLoading ? `loading` : `loaded`
        }`}
      >
        <div className="header" />

        <FiltersContainer className="filters-container">
          <button
            className={`filter ${
              filterSettings.activeArtistFilter.length === 0 &&
              filterSettings.activePriceFilter === null &&
              filterSettings.hideSoldItems === false
                ? `inactive`
                : `active`
            } ${isArtworkActive ? `active-artwork` : ``}`}
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
          >
            + Filter
          </button>

          <button
            onClick={() => setView(`gallery`)}
            className={view === `gallery` ? `active` : ``}
          >
            Spotlight
          </button>
          <div className="spacer">
            <p>—</p>
          </div>
          <button
            onClick={() => setView(`overview`)}
            className={view === `overview` ? `active` : ``}
          >
            Grid
          </button>
        </FiltersContainer>

        {artworks.length >= 1 ? (
          <Grid className={view === `overview` ? `overview` : `gallery`}>
            {artworks}
          </Grid>
        ) : (
          <Grid className="no-results">
            <div className="empty-filter-results">
              <p>
                <span className="no-results-text">
                  We are sorry, no results were found for this filtering.
                </span>
              </p>
              <p>Try different filtering parameters or reset your filter.</p>
            </div>
          </Grid>
        )}
      </Page>

      {isFiltersOpen && (
        <Filters
          artists={filteredArtists}
          isFiltersOpen={isFiltersOpen}
          setIsFiltersOpen={setIsFiltersOpen}
          filterSettings={filterSettings}
          setFilterSettings={setFilterSettings}
        />
      )}

      {isPopUpOpen && (
        <InactivityPopUp
          isPopUpOpen={isPopUpOpen}
          setIsPopUpOpen={setIsPopUpOpen}
        />
      )}
    </>
  );
};

export const query = graphql`
  query Shop {
    allShopifyProduct(
      sort: { updatedAt: DESC }
      filter: { status: { eq: ACTIVE } }
    ) {
      nodes {
        title
        handle
        featuredImage {
          gatsbyImageData(width: 1000)
          height
          width
        }
        metafields {
          key
          value
        }
        totalInventory
        priceRangeV2 {
          minVariantPrice {
            amount
          }
        }
      }
    }
    prismicShop {
      _previewable
      data {
        title {
          html
          text
        }
      }
    }
  }
`;

export default withPrismicPreview(Shop);
