import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageType } from "../components/context/page-type";

// Utils
import kebabCase from "lodash.kebabcase";

// Icons
import { Arrow, MobileArrow } from "../components/icons/arrows";

// Components
import { ArtistArtwork } from "../components/artist/artist-artwork";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;

  min-height: calc(${(props) => props.height}px - 190px - 90px);

  @media (max-width: 1110px) {
    min-height: calc(${(props) => props.height}px - 190px - 20px);
  }

  & .content {
    width: 100%;
  }

  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #fffcfa;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  & .shop-navigation-container {
    & a {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      transition: 250ms color ease;
      width: fit-content;

      & svg {
        margin: 0 0 0 30px;

        &.mobile-arrow {
          display: none;
        }

        & path {
          transition: 250ms stroke ease;
        }

        @media (max-width: 800px) {
          margin: 0 0 0 10px;

          &.desktop-arrow {
            display: none;
          }

          &.mobile-arrow {
            display: block;
          }
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #dbaf96;

          & svg {
            & path {
              stroke: #dbaf96;
            }
          }
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 15px;
  }
`;

const Details = styled.div`
  grid-column: 2 / 13;
  margin: 0 0 75px 0;

  @media (max-width: 800px) {
    grid-column: 1 / 7;
    margin: 0 0 60px 0;
  }

  & .title-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;

    & h1 {
      margin: 0 25px 0 0;

      @media (max-width: 500px) {
        margin: 0 10px 0 0;
      }
    }

    & p {
      margin: 0;
    }
  }

  & .biography-container {
    max-width: 925px;
    margin: 60px 0 0 0;

    @media (max-width: 1440px) {
      max-width: 735px;
    }

    @media (max-width: 1000px) {
      max-width: 565px;
    }

    & p {
      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const ContentContainer = styled.div`
  & .single-module {
    position: relative;
    margin: 0 0 120px 0;

    @media (max-width: 800px) {
      margin: 0 0 60px 0;
    }
  }

  & .paragraph-text-container {
    grid-column: 2 / 13;

    @media (max-width: 800px) {
      grid-column: 1 / 7;
    }

    & .paragraph-text {
      max-width: 925px;

      @media (max-width: 1440px) {
        max-width: 735px;
      }

      @media (max-width: 1000px) {
        max-width: 565px;
      }

      &.position-left {
      }

      &.position-right {
        margin: 0 0 0 auto;
      }
    }
  }

  & .quote-text-container {
    grid-column: 2 / 12;

    @media (max-width: 800px) {
      grid-column: 2 / 7;
    }

    & .quote-text {
      max-width: 420px;

      &.position-right {
        margin: 0 0 0 auto;
      }

      & p {
        margin: 0;

        font-size: 16px;
        line-height: 22px;

        @media (max-width: 1440px) {
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: 500px) {
          font-size: 10px;
          line-height: 14px;
        }

        & .quote-text-credit {
          display: block;
          margin: 5px 0 0 0;

          @media (max-width: 500px) {
            margin: 0;
          }

          & p {
            font-size: 12px;
            line-height: 17px;

            @media (max-width: 500px) {
              font-size: 9px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }

  & .artwork-container {
    & .aspect-ratio-container {
      // max-height: calc(${(props) => props.height}px - 250px);

      // @media (max-height: 800px) {
      //   max-height: calc(${(props) => props.height}px - 300px);
      // }

      & img {
        width: 100%;
        height: 100%;

        // max-height: calc(${(props) => props.height}px - 250px);

        // @media (max-height: 800px) {
        //   max-height: calc(${(props) => props.height}px - 300px);
        // }

        object-fit: contain !important;
        object-position: left;

        cursor: url(/icons/cursor-plus-on-white@1x.png) 12 12, pointer !important;
        cursor: -webkit-image-set(
              url(/icons/cursor-plus-on-white@1x.png) 1x,
              url(/icons/cursor-plus-on-white@2x.png) 2x
            )
            12 12,
          pointer !important;
      }
    }

    & p {
      font-size: 16px;
      line-height: 20px;

      margin: 15px 0 0 0;

      @media (max-width: 1440px) {
        font-size: 14px;
      }

      @media (max-width: 500px) {
        font-size: 11px;
        line-height: 15px;

        margin: 10px 0 0 0;
      }
    }

    &.position-left {
      &.size-5-columns {
        grid-column: 1 / 5;
      }

      &.size-6-columns {
        grid-column: 1 / 6;
      }

      &.size-9-columns {
        // grid-column: 1 / 10;
        grid-column: 1 / 7;

        @media (max-width: 800px) {
          grid-column: 1 / 7;
        }
      }
    }

    &.position-right {
      &.size-5-columns {
        grid-column: 8 / 13;

        @media (max-width: 800px) {
          grid-column: 3 / 7;
        }
      }

      &.size-6-columns {
        grid-column: 7 / 13;

        @media (max-width: 800px) {
          grid-column: 2 / 7;
        }
      }

      &.size-9-columns {
        // grid-column: 4 / 13;
        grid-column: 6 / 13;

        @media (max-width: 800px) {
          grid-column: 1 / 7;
        }
      }
    }
  }
`;

const Artist = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  // Image Sizing
  const height = use100vh();

  useEffect(() => {
    setPageType({
      page: `artist`,
      background: `#FFFCFA`,
      banner: true,
      bannerBackground: `#BDBFB7`,
      fillColor: `#DBAF96`,
      hoverColor: `#f9e0d2`,
      underlineColor: `#f9e0d2`,
    });
  }, []);

  const content = data.prismicArtist.data.body.map((content, index) => {
    if (content.slice_type === `paragraph`) {
      return (
        <Grid key={`single_paragraph_text_${index}`}>
          <div className="paragraph-text-container single-module">
            <div
              className={`paragraph-text position-${kebabCase(
                content.primary.text_position
              )}`}
            >
              <PrismicRichText field={content.primary.text.richText} />
            </div>
          </div>
        </Grid>
      );
    }

    if (content.slice_type === `quote`) {
      return (
        <Grid key={`single_quote_text_${index}`}>
          <div className="quote-text-container single-module">
            <div
              className={`quote-text position-${kebabCase(
                content.primary.quote_position
              )}`}
            >
              <PrismicRichText
                field={content.primary.quote.richText}
                components={{
                  paragraph: ({ children }) => <p>{children}</p>,
                  strong: ({ children }) => (
                    <span className="quote-text-credit">
                      <p>{children}</p>
                    </span>
                  ),
                }}
              />
            </div>
          </div>
        </Grid>
      );
    }

    if (content.slice_type === `artwork`) {
      return (
        <Grid key={`single_artwork_${index}`}>
          <ArtistArtwork content={content} data={data} />
        </Grid>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={`${data.prismicArtist.data.title.text} | RAW Editions`}
        seoText={data.prismicArtist.data.biography.text}
        seoImage={data.prismicArtist.data.thumbnail.url}
      />

      <Page height={height}>
        <div className="header" />

        <div className="content">
          <Grid>
            <Details>
              <div className="title-container">
                <PrismicRichText
                  field={data.prismicArtist.data.title.richText}
                  components={{
                    heading1: ({ children, index }) => (
                      <h1 className="uppercase" key={`single_title_${index}`}>
                        <Link
                          to={`/shop/`}
                          state={{
                            activeArtistFilter:
                              data.prismicArtist.data.title.text,
                          }}
                        >
                          {children}
                        </Link>
                      </h1>
                    ),
                  }}
                />

                <PrismicRichText
                  field={data.prismicArtist.data.caption.richText}
                  components={{
                    paragraph: ({ children, index }) => (
                      <p
                        className="small-text details"
                        key={`single_caption_${index}`}
                      >
                        {children}
                      </p>
                    ),
                  }}
                />
              </div>

              {data.prismicArtist.data.biography.text.length >= 1 && (
                <div className="biography-container">
                  <PrismicRichText
                    field={data.prismicArtist.data.biography.richText}
                  />
                </div>
              )}
            </Details>
          </Grid>

          <ContentContainer height={height}>{content}</ContentContainer>
        </div>

        <div className="shop-navigation-container">
          <Link
            to={`/shop/`}
            state={{ activeArtistFilter: data.prismicArtist.data.title.text }}
          >
            Shop all works by {data.prismicArtist.data.title.text}
            <Arrow />
            <MobileArrow />
          </Link>
        </div>
      </Page>
    </>
  );
};

export const query = graphql`
  query Artist($id: String) {
    prismicArtist(id: { eq: $id }) {
      _previewable
      url
      data {
        title {
          richText
          text
        }
        caption {
          richText
        }
        biography {
          richText
          text
        }
        thumbnail {
          gatsbyImageData
        }
        body {
          ... on PrismicArtistDataBodyParagraph {
            id
            slice_type
            primary {
              text_position
              text {
                richText
              }
            }
          }
          ... on PrismicArtistDataBodyQuote {
            id
            slice_type
            primary {
              quote {
                richText
              }
              quote_position
            }
          }
          ... on PrismicArtistDataBodyArtwork {
            id
            slice_type
            primary {
              artwork {
                image {
                  src
                  width
                  height
                }
                admin_graphql_api_id
                handle
                title
                status
              }
              artwork_size
              artwork_position
              artwork_title {
                richText
              }
              artwork_thumbnail {
                alt
                dimensions {
                  height
                  width
                }
                gatsbyImageData
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Artist);
