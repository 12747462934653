import React, { forwardRef, useState, useEffect } from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  position: relative;
  z-index: 100;

  & input {
    display: none;
  }

  & .marker {
    width: 18px;
    height: 18px;
    // cursor: pointer;

    z-index: 100;

    top: 0;
    left: 0;
    border: 1px solid #000;

    margin: 0;
    position: relative;

    &::after {
      content: "";
      width: 9px;
      height: 5px;
      position: absolute;
      top: 4px;
      left: 4px;

      border: 1px solid #000;
      border-top: none;
      border-right: none;

      background: transparent;

      opacity: 0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    // @media (hover: hover) and (pointer: fine) {
    &:hover,
    &.checked {
      &::after {
        opacity: 1;
      }
    }
    // }

    @media (max-width: 500px) {
      width: 12px;
      height: 12px;

      &::after {
        width: 6px;
        height: 3px;
        top: 2px;
        left: 2px;
      }
    }
  }
`;

export const Checkbox = forwardRef(
  ({ name, value, onChange, defaultChecked, ...rest }, forwardedRef) => {
    const [checked, setChecked] = useState(defaultChecked);

    useEffect(() => {
      onChange?.(checked);
    }, [checked, onChange]);

    return (
      <CheckboxContainer>
        <input
          ref={forwardedRef}
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
          required
        />
        <button
          type="button"
          className={`marker ${checked ? `checked` : ``}`}
          onClick={() => setChecked(!checked)}
        />
      </CheckboxContainer>
    );
  }
);
