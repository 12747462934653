import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { useMeasure } from "react-use";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageType } from "../components/context/page-type";

// Components
import { ContactMailchimpForm } from "../components/forms/contact-mailchimp-form";
import { GatsbyImage } from "gatsby-plugin-image";

const Page = styled.div`
  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #a8b8c1;

    z-index: 2;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  & h1 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;

    @media (max-width: 1440px) {
      font-size: 14px;
      line-height: 20px;
    }

    @media (max-width: 500px) {
      font-size: 9px;
      line-height: 11px;
    }
  }

  & p {
    & a {
      transition: 250ms color ease;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #e9cfc0;
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 800px) {
    grid-column-gap: 15px;
  }

  & .left-column {
    grid-column: 1 / 7;

    @media (max-width: 1000px) {
      order: 2;
      grid-column: 1 / 13;

      margin: 30px 0 0 0;
    }
  }

  & .right-column {
    grid-column: 8 / 13;

    @media (max-width: 1000px) {
      order: 1;
      grid-column: 1 / 13;
    }

    & .content-container {
      position: sticky;
      top: 190px;

      max-width: 570px;
      margin: 0 0 0 auto;

      @media (max-height: 790px) {
        top: 145px;
      }

      @media (max-height: 720px) {
        position: relative;
        top: unset;
      }
    }

    & .newsletter-container {
      & p {
        &:first-of-type {
          margin-top: 0;
        }
      }
    }

    & .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      margin: 0 0 70px 0;

      & p {
        margin: 0;
      }

      @media (max-width: 800px) {
        margin: 0 0 30px 0;
      }
    }
  }
`;

const PeopleContainer = styled.div`
  margin: ${(props) => props.marginTop}px 0 0 0;

  @media (max-width: 800px) {
    margin: 30px 0 0 0;
  }

  & h1 {
    margin: 0 0 7px 0;
  }

  & .single-person {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;

    margin: 0 0 220px 0;

    &:last-of-type {
      margin: 0;
    }

    @media (max-width: 800px) {
      margin: 0 0 60px 0;

      &:last-of-type {
        margin: 0;
      }
    }

    & .about-text {
      & p {
        // max-width: 570px;

        & strong {
          font-size: 16px;

          @media (max-width: 1440px) {
            font-size: 14px;
          }

          @media (max-width: 500px) {
            font-size: 9px;
          }
        }
      }
    }

    &:nth-of-type(odd) {
      & .thumbnail,
      & .contact-details {
        grid-column: 1 / 5;

        @media (max-width: 1100px) {
          grid-column: 1 / 6;
        }

        @media (max-width: 800px) {
          grid-column: 1 / 5;
        }
      }

      & .about-text {
        grid-column: 2 / 7;

        & p {
          margin: 1em 0 1em auto;
        }

        @media (max-width: 1440px) {
          & p {
            margin: 1em 0;
          }
        }

        @media (max-width: 1100px) {
          grid-column: 1 / 6;
        }

        @media (max-width: 800px) {
          grid-column: 2 / 7;
        }
      }
    }

    &:nth-of-type(even) {
      & .thumbnail {
        grid-column: 3 / 7;

        @media (max-width: 1100px) {
          grid-column: 1 / 6;
        }

        @media (max-width: 800px) {
          grid-column: 3 / 7;
        }
      }

      & .contact-details {
        grid-column: 1 / 5;

        @media (max-width: 1100px) {
          grid-column: 1 / 6;
        }
      }

      & .about-text {
        grid-column: 2 / 7;

        & p {
          margin: 1em 0 1em auto;
        }

        @media (max-width: 800px) {
          grid-column: 2 / 7;
        }
      }
    }
  }
`;

const Contact = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  // Get dims
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  useEffect(() => {
    setPageType({
      page: `contact`,
      background: `#A8B8C1`,
      banner: true,
      bannerBackground: `#A8B8C1`,
      fillColor: `#E9CFC0`,
      hoverColor: `#E9CFC0`,
      underlineColor: `#FBF7F5`,
    });
  }, []);

  const people = data.prismicContact.data.people.map((people, index) => (
    <div className="single-person" key={`single_person_container_${index}`}>
      <div className="thumbnail">
        {people.image.gatsbyImageData !== null && (
          <GatsbyImage
            image={people.image.gatsbyImageData}
            alt={people.image.alt}
            loading={`lazy`}
            onContextMenu={(e) => e.preventDefault()}
            draggable="false"
          />
        )}
      </div>

      <div className="contact-details small-text">
        <PrismicRichText field={people.contact_details.richText} />
      </div>

      <div className="about-text">
        <PrismicRichText field={people.text.richText} />
      </div>
    </div>
  ));

  return (
    <>
      <PageSeo
        seoTitle="Contact | RAW Editions"
        seoImage={null}
        seoText={null}
      />

      <Page>
        <div className="header" />

        <Grid>
          <div className="column left-column">
            <PeopleContainer marginTop={height + 70}>
              <h1>People</h1>
              {people}
            </PeopleContainer>
          </div>

          <div className="column right-column">
            <div className="content-container">
              <div className="grid" ref={ref}>
                <div className="address-text">
                  <PrismicRichText
                    field={data.prismicContact.data.address.richText}
                  />
                </div>

                <div className="social-links-text">
                  <PrismicRichText
                    field={data.prismicContact.data.social_links.richText}
                  />
                </div>
              </div>

              <div className="newsletter-container">
                <div className="text-container">
                  <PrismicRichText
                    field={data.prismicContact.data.newsletter_title.richText}
                  />
                  <PrismicRichText
                    field={data.prismicContact.data.newsletter_text.richText}
                  />
                </div>

                <ContactMailchimpForm />
              </div>
            </div>
          </div>
        </Grid>
      </Page>
    </>
  );
};

export const query = graphql`
  {
    prismicContact {
      _previewable
      data {
        people {
          image {
            gatsbyImageData
            dimensions {
              width
              height
            }
            alt
          }
          contact_details {
            richText
          }
          text {
            richText
          }
        }
        address {
          richText
        }
        social_links {
          richText
        }
        newsletter_title {
          richText
        }
        newsletter_text {
          richText
        }
      }
    }
  }
`;

export default withPrismicPreview(Contact);
