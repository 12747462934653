import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useClickAway } from "react-use";
import { use100vh } from "react-div-100vh";

// Utils
import { encodeForm } from "../utils/encode-form";

// Icons
import { CloseFiltersIcon } from "../icons/close-filters-icon";

const EnquiryFormContainer = styled.div`
  &.sell-form {
    background-color: #a8b8c1;

    cursor: url(/icons/cursor-circle-on-white@1x.png) 9 9, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/cursor-circle-on-white@1x.png) 1x,
          url(/icons/cursor-circle-on-white@2x.png) 2x
        )
        9 9,
      pointer !important;

    & button,
    & textarea,
    & input {
      cursor: url(/icons/cursor-circle-on-white@1x.png) 9 9, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/cursor-circle-on-white@1x.png) 1x,
            url(/icons/cursor-circle-on-white@2x.png) 2x
          )
          9 9,
        pointer !important;
    }
  }

  &.enquire-form {
    background-color: #e9cfc0;

    cursor: url(/icons/cursor-circle-on-buff@1x.png) 9 9, pointer !important;
    cursor: -webkit-image-set(
          url(/icons/cursor-circle-on-buff@1x.png) 1x,
          url(/icons/cursor-circle-on-buff@2x.png) 2x
        )
        9 9,
      pointer !important;

    & button,
    & textarea,
    & input {
      cursor: url(/icons/cursor-circle-on-buff@1x.png) 9 9, pointer !important;
      cursor: -webkit-image-set(
            url(/icons/cursor-circle-on-buff@1x.png) 1x,
            url(/icons/cursor-circle-on-buff@2x.png) 2x
          )
          9 9,
        pointer !important;
    }
  }

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;

  width: 100%;
  max-width: 610px;

  padding: 20px;

  @media (max-width: 800px) {
    padding: 15px;
  }

  color: #000;

  & h2 {
    font-size: 16px;
    line-height: 22px;

    padding: 0 0 200px 0;

    @media (max-width: 800px) {
      padding: 0 0 100px 0;
    }

    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: 90vw;
  }

  & .inner-form-container {
    height: 100%;
  }

  & form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;

    // height: calc(${(props) => props.height}px - 200px - 45px);

    @media (max-width: 800px) {
      // height: calc(${(props) => props.height}px - 100px - 37px);
    }

    & .top-fields {
      width: 100%;
    }

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      margin: 0 0 50px 0;

      & label {
        font-size: 16px;
        line-height: 28px;

        @media (max-width: 500px) {
          font-size: 10px;
          line-height: 14px;
        }
      }

      & textarea {
        width: 100%;
        background-color: transparent;

        padding: 0;
        border: 0;

        color: rgba(0, 0, 0, 0.4);

        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.04em;

        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 22px;
        }

        @media (max-width: 500px) {
          font-size: 10px;
          line-height: 14px;
        }
      }

      & input {
        width: 100%;

        padding: 0;
        margin: 0;
        border: 0;

        color: #000;
        background: transparent;

        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.04em;

        border-bottom: 1px solid #658ba5;

        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 22px;
        }

        @media (max-width: 500px) {
          font-size: 10px;
          line-height: 14px;
        }

        ::-webkit-input-placeholder {
          font-family: "Citerne-Light", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.04em;

          color: rgba(0, 0, 0, 0.4);

          @media (max-width: 500px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
        ::-moz-placeholder {
          font-family: "Citerne-Light", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.04em;

          color: rgba(0, 0, 0, 0.4);

          @media (max-width: 500px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
        :-ms-input-placeholder {
          font-family: "Citerne-Light", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.04em;

          color: rgba(0, 0, 0, 0.4);

          @media (max-width: 500px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
        :-moz-placeholder {
          font-family: "Citerne-Light", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.04em;

          color: rgba(0, 0, 0, 0.4);

          @media (max-width: 500px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }
  }

  & .buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-end;

    width: 100%;

    & button {
      height: 50px;
      min-width: 115px;

      padding: 0 15px;
      margin: 0 20px 0 0;

      line-height: 43px;
      text-align: center;

      border: 1px solid #000000;
      transition: 250ms border-color ease, 250ms background-color ease;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-color: #87a3b4;
          background-color: #87a3b4;
        }
      }

      @media (max-width: 800px) {
        height: 30px;
        min-width: 70px;

        line-height: 27px;

        padding: 0 9px;
        margin: 0 15px 0 0;

        & svg {
          width: 7px;
          height: auto;
        }
      }
    }
  }

  &.sell-form {
    & form {
      & .field {
        & input {
          border-bottom: 1px solid #e9cfc0;
        }
      }
    }

    & .buttons-container {
      & button {
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            border-color: #e9cfc0;
            background-color: #e9cfc0;
          }
        }
      }
    }
  }

  & .thank-you-message {
    padding: 200px 20px 0 0;

    & p {
      margin: 0;
    }
  }
`;

export const ProductEnquiryForm = ({
  title,
  url,
  artist,
  setIsProductEnquiryOpen,
  formType,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(null);

  const height = use100vh();

  const websiteUrl = url;
  const subjectLine = `${
    formType === `sell`
      ? `Sell Enquiry: ${artist} | ${title}`
      : `Artwork Enquiry: ${artist} | ${title}`
  }`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: "",
      url: url,
      message:
        formType === `sell`
          ? `I am interested in selling an impression of this work. [Please include a brief description of the work you are interested in selling, condition and provenance details if available.]`
          : `I'm interested in purchasing this work. Could you please provide more information about [price, availability, viewing options, etc.]?`,
    },
  });

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsProductEnquiryOpen(false);
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeForm({
          "form-name": formType === `sell` ? `sell-enquiry` : `artwork-enquiry`,
          subject: data.subject,
          ...data,
        }),
      });

      if (!response.ok) throw new Error("Submission failed");

      setIsFormVisible(false);
      setFormMessage(
        "Thank you for your interest. We will get back to you within 1-2 working days."
      );
      reset();
    } catch (error) {
      console.log(error);
      setIsFormVisible(false);
      setFormMessage(
        "An error occurred while submitting your enquiry. Please email us directly at: info@raweditions.com"
      );
    }
  };

  useEffect(() => {
    if (isFormVisible === false) {
      const closeForm = setTimeout(() => {
        setIsFormVisible(false);
        const fadeInForm = setTimeout(() => {
          setIsFormVisible(true);
        }, 750);
        return () => clearTimeout(fadeInForm);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [isFormVisible]);

  return (
    <EnquiryFormContainer
      className={formType === `sell` ? `sell-form` : `enquire-form`}
      ref={ref}
      height={height}
    >
      <div className="inner-form-container">
        {isFormVisible && (
          <>
            <form
              name={formType === `sell` ? `sell-enquiry` : `artwork-enquiry`}
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="top-fields">
                <h2>
                  {formType === `sell` ? (
                    <>
                      Have one to sell? <br />
                      RAW is always looking for new acquisitions.
                    </>
                  ) : (
                    <>
                      <span className="artwork-title">{title}</span>
                      <br />
                      <span className="artist-name uppercase">{artist}</span>
                    </>
                  )}
                </h2>

                {formType === `sell` ? (
                  <input type="hidden" name="form-name" value="sell-enquiry" />
                ) : (
                  <input
                    type="hidden"
                    name="form-name"
                    value="artwork-enquiry"
                  />
                )}
                <input
                  type="hidden"
                  name="url"
                  value={websiteUrl}
                  {...register("url")}
                />
                <input type="hidden" name="bot-field" />
                <input
                  type="hidden"
                  name="subject"
                  value={subjectLine}
                  {...register("subject")}
                />

                <div className="field">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    aria-required="true"
                    aria-label="E-mail"
                    placeholder={
                      errors.email
                        ? `Enter a valid email address`
                        : `Email Address`
                    }
                    {...register("email", { required: true })}
                  />
                </div>
                <div className="field">
                  <label htmlFor="message">Personalise your message</label>
                  <textarea
                    rows="6"
                    {...register("message", { required: true })}
                  />
                </div>
              </div>

              <div className="buttons-container">
                <button className="enquire-button">Enquire</button>
                <button
                  onClick={() => setIsProductEnquiryOpen(false)}
                  className="close-button"
                  type="button"
                >
                  <CloseFiltersIcon /> Close
                </button>
              </div>
            </form>
          </>
        )}

        {!isFormVisible && (
          <div className="thank-you-message" id="thank_you_message">
            {formMessage}
          </div>
        )}
      </div>
    </EnquiryFormContainer>
  );
};
