import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Icons
import { MailchimpArrow, MobileArrow } from "../icons/arrows";

// Components
import { Checkbox } from "./checkbox";

// Utils
// import { convertToKebabCase } from "../utils/convert-to-kebab";
// import { encodeForm } from "../utils/encode-form";

const EnquiryFormContainer = styled.div`
  & form {
    max-width: 450px;
    margin: 40px 0 0 0;

    @media (max-width: 800px) {
      margin: 30px 0 0 0;
    }

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      border-bottom: 1px solid #000;

      margin: 0 0 15px 0;

      & input,
      & button,
      & textarea {
        cursor: url(/icons/cursor-circle-on-white@1x.png) 9 9, pointer !important;
        cursor: -webkit-image-set(
              url(/icons/cursor-circle-on-white@1x.png) 1x,
              url(/icons/cursor-circle-on-white@2x.png) 2x
            )
            9 9,
          pointer !important;
      }

      & input {
        width: 100%;

        padding: 0;
        margin: 0;
        border: 0;

        color: #000;
        background: transparent;

        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.04em;

        @media (max-width: 1440px) {
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: 500px) {
          font-size: 11px;
          line-height: 15px;
        }

        ::-webkit-input-placeholder {
          font-family: "Citerne-Light", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: rgba(0, 0, 0, 0.4);
        }
        ::-moz-placeholder {
          font-family: "Citerne-Light", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: rgba(0, 0, 0, 0.4);
        }
        :-ms-input-placeholder {
          font-family: "Citerne-Light", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: rgba(0, 0, 0, 0.4);
        }
        :-moz-placeholder {
          font-family: "Citerne-Light", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    & .privacy-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;

      margin: 0 0 10px 0;

      & p {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.04em;

        margin: 0;

        @media (max-width: 1440px) {
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: 500px) {
          font-size: 10px;
          line-height: 14px;
        }
      }

      & .gdpr-checkbox {
        width: 18px;
        height: 18px;

        position: relative;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox label {
        width: 18px;
        height: 18px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #000;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox label:after {
        content: "";
        width: 9px;
        height: 5px;
        position: absolute;
        top: 4px;
        left: 4px;
        border: 1px solid #000;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);

        @media (max-width: 500px) {
          width: 6px;
          height: 3px;
          top: 3px;
          left: 2px;
        }
      }

      & .gdpr-checkbox label:hover::after {
        opacity: 1;
      }

      & .gdpr-checkbox input[type="checkbox"] {
        width: 18px;
        height: 18px;

        visibility: hidden;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }

      & .errors {
        flex: 100%;
        margin: 5px 0 0 0;

        & p {
          color: #e9cfc0;
        }
      }
    }

    & .submit-button {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      font-size: 20px;
      line-height: 44px;
      color: #e9cfc0;

      transition: 250ms color ease;

      & svg {
        width: 65px;
        margin: 0 0 0 20px;

        & path {
          transition: 250ms stroke ease;
        }

        &.mobile-arrow {
          display: none;

          & path {
            fill: #e9cfc0;
          }
        }

        @media (max-width: 800px) {
          &.desktop-arrow {
            display: none;
          }

          &.mobile-arrow {
            display: block;
            width: auto;

            margin: 0 0 0 15px;
          }
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #000;

          & svg {
            & path {
              stroke: #000;
            }
          }
        }
      }

      @media (max-width: 800px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media (max-width: 500px) {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }

  & .thank-you-message {
    margin: 70px 0 0 0;

    & p {
      color: #e9cfc0;
    }
  }
`;

export const ContactMailchimpForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [formMessage, setFormMessage] = useState(``);
  const [checked, setChecked] = useState(false);

  const onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      const result = await addToMailchimp(data.email, {
        FNAME: data.fName,
        LNAME: data.lName,
        MMERGE5: data.MMERGE5,
      });

      if (
        result.result === "error" &&
        result.msg.includes("already subscribed")
      ) {
        setFormMessage(
          "Thank you for signing up to the RAW Editions mailing list."
        );
        reset();
      } else if (result.result !== "error") {
        setFormMessage(
          "Thank you for signing up to the RAW Editions mailing list."
        );
        reset();
      }
    } catch (error) {
      console.error("Mailchimp submission error:", error);
    }
  };

  useEffect(() => {
    if (formMessage !== ``) {
      const closeForm = setTimeout(() => {
        setFormMessage(``);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [formMessage]);

  return (
    <EnquiryFormContainer>
      {formMessage === `` ? (
        <form
          name="signup-form"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="field">
            <input
              id="fName"
              type="text"
              autoComplete="given-name"
              aria-required="true"
              aria-label="First Name"
              placeholder={`First name`}
              {...register("fName", { required: true })}
            />
          </div>

          <div className="field">
            <input
              id="lName"
              type="text"
              autoComplete="family-name"
              aria-required="true"
              aria-label="Last Name"
              placeholder={`Last name`}
              {...register("lName", { required: true })}
            />
          </div>

          <div className="field">
            <input
              id="email"
              type="email"
              autoComplete="email"
              aria-required="true"
              aria-label="Email address"
              placeholder={`Email address`}
              {...register("email", { required: true })}
            />
          </div>

          <div className="field">
            <input
              id="artists"
              type="text"
              aria-label="Artists you collect (optional)"
              placeholder={`Artists you collect (optional)`}
              {...register("MMERGE5")}
            />
          </div>

          <div className="privacy-container">
            <p>
              You agree to our{" "}
              <Link to={`/support/`} state={{ open: `business` }}>
                privacy policy
              </Link>
            </p>

            <div className="gdpr-checkbox" onClick={() => setChecked(!checked)}>
              <input
                type="checkbox"
                id="gdpr-checkbox"
                checked={checked}
                {...register("gdprcheck", { required: true })}
                onChange={(e) => setChecked(e.target.checked)}
              />
              <label htmlFor="gdprcheck"></label>
            </div>

            {errors.gdprcheck && (
              <div className="errors">
                <p>Please tick this box to proceed</p>
              </div>
            )}
          </div>

          <button type="submit" className="submit-button">
            Submit
            <MailchimpArrow />
            <MobileArrow />
          </button>
        </form>
      ) : (
        <div className="thank-you-message">
          <p>{formMessage}</p>
        </div>
      )}
    </EnquiryFormContainer>
  );
};
