import React, { useContext, useEffect, useState, useRef } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { useMeasure } from "react-use";
import { Helmet } from "react-helmet";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageType } from "../components/context/page-type";

// Utils
import { ImageOrientation } from "../components/utils/image-orientation";
import { GatsbyImage } from "gatsby-plugin-image";

const Page = styled.div`
  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #fffcfa;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }
`;

const ArtistsContainer = styled.div`
  min-height: calc(100vh - 190px - 50px - 90px);

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & li {
      display: block;

      & a {
        display: block;

        & p {
          font-size: 30px;
          line-height: 44px;
          letter-spacing: 0.08em;
          text-transform: uppercase;

          transition: 250ms color ease;

          margin: 0;

          &::after {
            content: "—";
            color: #dbaf96;
          }

          @media (max-width: 1440px) {
            font-size: 24px;
            line-height: 36px;
          }
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          & a {
            & p {
              color: #dbaf96;
            }
          }
        }
      }

      &:last-of-type {
        & p {
          &::after {
            content: "";
          }
        }
      }
    }

    @media (max-width: 800px) {
      display: block;
      flex-direction: unset;
      flex-wrap: unset;

      & li {
        & a {
          & p {
            font-size: 20px;
            line-height: 28px;

            &::after {
              content: "";
              display: none;
            }
          }
        }
      }
    }
  }
`;

const DesktopCaption = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;

  cursor: none;

  width: 0;

  overflow: hidden;

  & img {
    position: fixed;

    width: auto;
    height: auto;

    object-fit: contain !important;

    &.landscape {
      max-height: 26vh;
    }

    &.portrait {
      max-width: 15vw;
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const RandomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const Artists = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  // Get dims
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  // Save active slide
  const [activeSlide, setActiveSlide] = useState(null);

  useEffect(() => {
    setPageType({
      page: `home`,
      background: `#FFFCFA`,
      banner: true,
      bannerBackground: `#BDBFB7`,
      fillColor: `#DBAF96`,
      hoverColor: `#f9e0d2`,
      underlineColor: `#f9e0d2`,
    });
  }, []);

  const filteredArtists = data.prismicArtists.data.artists.filter(
    (artist) => artist.artist.document !== null
  );

  const allArtistImages = filteredArtists
    .filter((artist) => artist.artist.document.data.thumbnail !== null)
    .map((artist) => artist.artist.document.data.thumbnail);

  const preloadedSrcImages = allArtistImages.map((image, index) => (
    <link
      rel="preload"
      href={image.src}
      as="image"
      key={`preloaded_image_${index}`}
    />
  ));

  const preloadedSrcSetImages = allArtistImages.map((image, index) => (
    <link
      rel="preload"
      href={image.srcSet}
      as="image"
      key={`preloaded_image_${index}`}
    />
  ));

  const artists = filteredArtists.map((artist, index) => (
    <li
      key={`single_homepage_artist_${index}_${artist.artist.document.id}`}
      onMouseOver={() => {
        if (activeSlide !== artist.artist.document.data.thumbnail) {
          setActiveSlide(artist.artist.document.data.thumbnail);
        }
      }}
      onMouseLeave={() => setActiveSlide(null)}
    >
      <Link to={artist.artist.document.url}>
        <p>{artist.artist.document.data.title.text}</p>
      </Link>
    </li>
  ));

  return (
    <>
      <PageSeo
        seoTitle="Artists | RAW Editions"
        seoText={null}
        seoImage={null}
      />
      <Helmet>
        {preloadedSrcImages}
        {preloadedSrcSetImages}
      </Helmet>

      <Page>
        <div className="header" />

        <ArtistsContainer ref={ref}>
          <ol>
            {artists}

            <li>
              <Link to={`/shop/`} state={{ resetFilters: true }}>
                <p>All Artists</p>
              </Link>
            </li>
          </ol>
        </ArtistsContainer>

        <DesktopCaption>
          {activeSlide !== null && activeSlide.gatsbyImageData !== null && (
            <GatsbyImage
              className={ImageOrientation(activeSlide)}
              style={{
                top: `${RandomIntFromInterval(
                  190,
                  height - 15 - height / 3
                )}px`,
                left: `${RandomIntFromInterval(15, width - 15 - width / 3)}px`,
              }}
              image={activeSlide.gatsbyImageData}
              alt={activeSlide.alt}
              loading={`lazy`}
            />
          )}
        </DesktopCaption>
      </Page>
    </>
  );
};

export const query = graphql`
  {
    prismicArtists {
      _previewable
      data {
        title {
          richText
        }
        artists {
          artist {
            document {
              ... on PrismicArtist {
                id
                url
                data {
                  title {
                    text
                  }
                  thumbnail {
                    gatsbyImageData
                    alt
                    dimensions {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Artists);
